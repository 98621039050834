<template>
<v-responsive>
    <v-card :loading="loading" class="card-charge mb-2">
        <v-card-title class="subtitle-1">
            <v-icon left>mdi-cash-usd</v-icon>
            <strong>충전</strong>
        </v-card-title>
        <v-divider/>
        <v-card-text>
            <div class="d-flex">
                <strong class="shrink">당일 충전 횟수</strong>
                <v-spacer/>
                <span class="shrink">
                    <strong>{{ calculation.charge.count.format() }}</strong>회
                </span>
            </div>
            <div class="d-flex">
                <strong class="shrink">당일 충전 금액</strong>
                <v-spacer/>
                <span class="shrink">
                    <strong>{{ calculation.charge.price.format() }}</strong>원
                </span>
            </div>
        </v-card-text>
    </v-card>

    <v-card :loading="loading" class="card-exchange mb-2">
        <v-card-title class="subtitle-1">
            <v-icon left>mdi-cash</v-icon>
            <strong>출금</strong>
        </v-card-title>
        <v-divider/>
        <v-card-text>
            <div class="d-flex">
                <strong class="shrink">당일 출금 횟수</strong>
                <v-spacer/>
                <span class="shrink">
                    <strong>{{ calculation.exchange.count.format() }}</strong>회
                </span>
            </div>
            <div class="d-flex">
                <strong class="shrink">당일 출금 금액</strong>
                <v-spacer/>
                <span class="shrink">
                    <strong>{{ calculation.exchange.price.format() }}</strong>원
                </span>
            </div>
        </v-card-text>
    </v-card>

    <v-card :loading="loading" class="card-order mb-2">
        <v-card-title class="subtitle-1">
            <v-icon left>mdi-ticket-confirmation</v-icon>
            <strong>주문</strong>
        </v-card-title>
        <v-divider/>
        <v-card-text>
            <div class="d-flex">
                <strong class="shrink">당일 주문 횟수</strong>
                <v-spacer/>
                <span class="shrink">
                    <strong>{{ calculation.order.count.format() }}</strong>회
                </span>
            </div>
            <div class="d-flex">
                <strong class="shrink">당일 주문 금액</strong>
                <v-spacer/>
                <span class="shrink">
                    <strong>{{ calculation.order.price.format() }}</strong>원
                </span>
            </div>
        </v-card-text>
    </v-card>
    
    <v-card :loading="loading" class="card-reward mb-2">
        <v-card-title class="subtitle-1">
            <v-icon left>mdi-video-check</v-icon>
            <strong>적립</strong>
        </v-card-title>
        <v-divider/>
        <v-card-text>
            <div class="d-flex">
                <strong class="shrink">당일 적립 횟수</strong>
                <v-spacer/>
                <span class="shrink">
                    <strong>{{ calculation.reward.count.format() }}</strong>회
                </span>
            </div>
            <div class="d-flex">
                <strong class="shrink">당일 적립 금액</strong>
                <v-spacer/>
                <span class="shrink">
                    <strong>{{ calculation.reward.price.format() }}</strong>원
                </span>
            </div>
        </v-card-text>
    </v-card>
</v-responsive>
</template>

<script>
import api from "@/api";
export default {
    data(){
        return {
            loading: true,
            calculation: {
                charge: { count: 0, price: 0 },
                exchange: { count: 0, price: 0 },
                order: { count: 0, price: 0 },
                reward: { count: 0, price: 0 }
            }
        };
    },
    mounted(){
        this.init();
    },
    methods: {
        init(){
            var startDate = new Date(); startDate.setHours(0,0,0,0);
            var endDate = new Date(); endDate.setHours(23,59,59,999);

            startDate = startDate.getTime();
            endDate = endDate.getTime();

            api.console.calculations.getCalculations({
                headers: {
                    skip: 0,
                    limit: 1,
                },
                params: {
                    startDate,
                    endDate
                }
            }).then(data => {
                this.calculation = Object.assign({
                    charge: {
                        price: 0,
                        count: 0
                    },
                    exchange: {
                        price: 0,
                        count: 0
                    },
                    order: {
                        price: 0,
                        count: 0
                    },
                    reward: {
                        price: 0,
                        count: 0
                    }
                }, data.calculations[0]);
            }).finally(() => {
                this.loading = false;
            });
        }
    }
}
</script>

<style scoped>
.card-charge .v-card__title { border-left: 4px #FFEB3B solid; }
.card-exchange .v-card__title { border-left: 4px #4CAF50 solid; }
.card-order .v-card__title { border-left: 4px #2196F3 solid; }
.card-reward .v-card__title { border-left: 4px #F44336 solid; }
</style>